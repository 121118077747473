.bundleTitle {
  padding: 12px;
}

.removeListingButton {
  border: none;
  cursor: pointer;
  padding-left: 8px;
  padding-right: unset;
}

@media only screen and (min-width: 1024px) {
  .removeListingButton {
    padding-left: 12px;
  }
}

.removeIcon {
  color: var(--gray60);
  width: 20px;
}

.removeIcon:hover {
  color: var(--gray80);
}

@media only screen and (min-width: 1024px) {
  .removeIcon {
    width: 24px;
  }
}
