.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listingsContainer {
  width: 100%;
}

/* Icon css based on https://github.com/YIZHUANG/react-multi-carousel/blob/abd7ce4e4edb87cdd0231f63a48039f70db7da98/stories/style.css */
.leftIcon {
  position: absolute !important;
  left: 30px;
  z-index: 1;
  border-radius: 50%;
  background-color: white;
  opacity: 0.8;
  cursor: pointer;
}

.rightIcon {
  position: absolute !important;
  right: 30px;
  z-index: 1;
  border-radius: 50%;
  background-color: white;
  opacity: 0.8;
  cursor: pointer;
}
