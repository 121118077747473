.shipping {
  padding: 16px;
}

.shippingTitle {
  color: var(--gray80);
  margin: 0;
}

.divider {
  background-color: var(--gray20);
}

.payment {
  padding: 0 16px 16px;
}

.error {
  padding: 0 16px;
  text-align: center;
}
