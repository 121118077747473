.hidden {
  display: none !important;
}

.label {
  padding: 0;
}

.label label {
  padding: 0;
  margin: 0;
}

.banner {
  background-color: var(--gray10);
  border-radius: 12px;
  cursor: pointer;
}
