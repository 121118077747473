:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */

  --TopbarDesktop_iconHeightOrSize: 16px;
}

/* Desktop */
.root {
  padding: 0 24px;
}

.label {
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  display: inline-block;
  margin: 24px 0;
  text-decoration: inherit;
}

@media only screen and (min-width: 768px) {
  .label {
    margin: 24px 0;
  }
}

.linkFont {
  font-weight: var(--topbarLinkFontWeight);
  text-transform: var(--topbarLinkTextTransform);
  line-height: 22px;
  letter-spacing: var(--topbarLinkLetterSpacing);
  font-size: var(--topbarLinkFontSizeSmall);
}

@media only screen and (min-width: 768px) {
  .linkFont {
    font-weight: var(--topbarLinkFontWeight);
    font-size: var(--topbarLinkFontSizeMedium);
    line-height: 24px;
  }
}

.contentWrapper {
  height: var(--topbarHeightDesktop);
  max-width: var(--contentMaxWidth);
}

.section {
  display: flex;
  align-items: center;
}

/* Corresponds to the left middle and right section of the top bar */
.shopSection {
  justify-content: flex-start;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.linksSection {
  justify-content: flex-end;
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.logoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);
}

@media only screen and (min-width: 1024px) {
  .searchLink {
    padding-left: 0;
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);
}

@media only screen and (min-width: 1024px) {
  .topbarSearchWithLeftPadding {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.sectionWrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  padding: 0 8px;
}

.sectionLink {
  composes: linkFont;
  margin-top: 0;
  margin-bottom: 0;
  display: inline;
  padding: 0;
  border: none;
  color: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .sectionLink {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionLink:hover {
  /* Hover underline is controlled by TypographyWrapper */
  -webkit-text-decoration: none;
  text-decoration: none;
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 8px;
}

.profileMenuLabel:active {
  border-bottom: 0;
}

.profileMenuIsOpen:hover {
  border-bottom: 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

.shoppingBagButton {
  border-width: 0;
  background-color: transparent;
  display: unset;
  max-width: 56px;
}

.shoppingBagButton:hover {
  cursor: pointer;
}

.linkSection {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

/* Authentication */
.signupLink,
.loginLink,
.link {
  margin: 0;
  border: none;
  color: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  padding: 0 8px;
}

.signupLink:hover,
.loginLink:hover,
.link:hover {
  /* Hover underline is controlled by TypographyWrapper */
  -webkit-text-decoration: none;
  text-decoration: none;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.currentPage {
  position: relative;
}

.currentPage .menuItemBorder {
  width: 6px;
  background-color: var(--matterColorDark);
}

.currentPage:hover .menuItemBorder {
  background-color: var(--matterColorDark);
}

.menuLink {
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  text-align: left;
  transition: var(--transitionStyleButton);
}

@media only screen and (min-width: 768px) {
  .menuLink {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.menuLink:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.menuLink:hover .menuItemBorder {
  width: 6px;
  background-color: var(--marketplaceColor);
}

.logoutButtonWrapper {
  display: flex;
  position: relative;
  width: 100%;
  min-width: 276px;
  padding: 4px 24px;
  transition: var(--transitionStyleButton);
}

.logoutButtonWrapper:hover {
  cursor: pointer;
}

.logoutButtonWrapper:hover .menuItemBorder {
  width: 6px;
  background-color: var(--marketplaceColor);
}

.logoutButton {
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-size: 14px;
  width: 100%;
  text-align: left;
  white-space: nowrap;
}

@media only screen and (min-width: 768px) {
  .logoutButton {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.logoutButton:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.transparentTopbarForeground {
  filter: brightness(0) invert(1);
}
