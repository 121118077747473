.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Remove link's hover effect */
.root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.root h4 {
  margin: 0;
}

.menuOverlayWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.menuOverlay {
  /* Positioning */
  composes: menuOverlayWrapper;

  /* Overlay */
  background-color: var(--matterColorAnti);
  mix-blend-mode: multiply;
  opacity: 0;
  transition: var(--transitionStyleButton);
}

.menuOverlayContent {
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);

  /* Positioning */
  composes: menuOverlayWrapper;

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
}

@media only screen and (min-width: 768px) {
  .menuOverlayContent {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  overflow: hidden;
}

.threeToTwoWrapperHover:hover {
  cursor: pointer;
  transform: scale(1.02);
  box-shadow: var(--boxShadowListingCard);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 100%; /* 1:1 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
  margin-bottom: 1px; /* Fix 1px bug */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  composes: menuOverlayWrapper;

  /* This makes sure if the image isn't the containers aspect ratio, it will center it in the middle */
  height: 100%;
  object-fit: contain;
  vertical-align: middle;
  border-radius: var(--borderRadiusHalf);
  width: 100%;
}

.menubarWrapper {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
}

.menubarGradient {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Gradient */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAqCAYAAACUV/o4AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAGhJREFUKBVjZGBgKANibiAGga8sQAKEf4N4QMAC4vwH4r8gHgiABOAcmMAfEAMGsKrA0DIqAAsvHGFKkwACRRQjzGJQRP2DcUA0LOZAEQ5S9R8mAJIEA6wCyIYSqYVSz4FcxwR1E5gCAD7HLabS/HFUAAAAAElFTkSuQmCC');
  background-repeat: repeat;
  opacity: 0.5;
}

.menubar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.menuLabel {
  padding: 8px;
  height: auto;
}

.iconWrapper {
  color: var(--matterColorLight);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transitionStyleButton);
  background-color: rgba(0, 0, 0, 0.3);
}

.iconWrapper:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.listingMenuIsOpen .iconWrapper {
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--matterColorLight);
}

.menuContent {
  position: absolute;
  right: 0;
  z-index: var(--zIndexPopup);
  border-radius: var(--borderRadiusHalf);
  box-shadow: var(--boxShadowPopup);
}

.menuItemWrapper {
  border: 1px solid var(--gray20);
}

.menuItemWrapper:last-child {
  border-top: none;
  border-bottom-left-radius: var(--borderRadiusHalf);
  border-bottom-right-radius: var(--borderRadiusHalf);
}

.menuItemWrapper:first-child {
  border-top: 1px solid var(--gray20);
  border-top-left-radius: var(--borderRadiusHalf);
  border-top-right-radius: var(--borderRadiusHalf);
}

.menuItem {
  display: inline;
  margin: 0;
  border: none;
  color: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  font-weight: var(--fontWeightMedium);
  white-space: nowrap;
  text-align: left;
  padding: 8px 14px;
  border-radius: var(--borderRadiusHalf);
  width: 100%;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  transition: var(--transitionStyleButton);
}

.menuItem:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .menuItem {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.menuItemDisabled {
  color: var(--matterColorNegative);
}

.menuItemDisabled:hover {
  color: var(--matterColorNegative);
}

.editListingLink {
  margin: 0;
  padding: 0;
  color: var(--matterColorWhite);
  width: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 8px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-shrink: 0;
  padding: 0;

  /* padding-top: 4px; */

  /* padding-bottom: 2px; */
}

/* color: */

@media only screen and (min-width: 768px) {
  .price {
    /* padding-top: 5px; */

    /* padding-bottom: 3px; */
  }
}

.priceValue {
  font-weight: var(--fontWeightSemiBold);
  margin-right: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .priceValue {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .perUnit {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  display: inline;
  margin: 0;
  border: none;
  -webkit-text-decoration: none;
  text-decoration: none;

  /* Font */
  color: var(--matterColor);
  text-align: left;
  padding-top: 3px;
  padding-bottom: 3px;
}

@media only screen and (min-width: 768px) {
  .title {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

/* Solid gray background for draft listings without image */
.draftNoImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--matterColor);
}

.listingActionButton {
  width: 100%;
  max-width: 224px;
  padding: var(--listingCardButtonPadding);
  min-height: auto;
}

.finishListingDraftLink {
  cursor: pointer;
}

.menu {
  display: none;
}

.cardIsOpen {
  display: block;
}

.manageLinks {
  line-height: 18px;
  padding-top: 2px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .manageLinks {
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.getPaidButton {
  font-size: 14px;
  width: auto;
  min-height: 0;
  padding: 5px 10px;
  background-color: var(--successColor);
}

.status,
.orderNumber,
.customer {
  margin: 0;
}

.manageLinksSeparator {
  margin: 0 3px 0 3px;
}

.manageTradeInCheckbox {
  height: 32px;
  margin-top: -1px;
  margin-right: 0;
  padding-left: 8px;
  align-self: baseline;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.boxClassName {
  transform: scale(1.3);
  transform-origin: top right;
}

.syncShopifyInventoryTooltip {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 1;
}
