.root {
  position: relative;
  display: inline-block;
}

.label {
  font-family: var(--buttonFontFamily), var(--fontFamily), 'sofiapro', sans-serif !important;
  font-weight: var(--buttonFontWeight);
  font-size: var(--buttonFontSize);
  line-height: var(--buttonLineHeight);
  letter-spacing: var(--buttonLetterSpacing);
  display: block;
  width: auto;
  height: auto;
  min-height: 0;
  margin: 0;
  padding: 9px 16px 10px 16px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--matterColorNegative);
  border-radius: var(--borderRadiusHalf);
  background-color: var(--matterColorLight);
  color: var(--matterColor);
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: var(--buttonTextTransform);
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.label:focus {
  outline: none;
  background-color: var(--matterColorLight);
  border-color: transparent;
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--boxShadowFilterButton);
}

.labelSelected {
  font-family: var(--buttonFontFamily), var(--fontFamily), 'sofiapro', sans-serif !important;
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--buttonFontSize);
  line-height: var(--buttonLineHeight);
  letter-spacing: var(--buttonLetterSpacing);
  display: block;
  width: auto;
  height: auto;
  min-height: 0;
  margin: 0;
  padding: 9px 16px 10px 16px;
  border-radius: var(--borderRadiusHalf);
  border: 1px solid var(--marketplaceColor);
  background-color: var(--buttonColor);
  color: var(--buttonTextColor);
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: var(--buttonTextTransform);
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.labelSelected:hover,
.labelSelected:focus {
  border: 1px solid var(--marketplaceColorDark);
}

.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--borderRadiusHalf);
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 15px 30px 17px 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
