.pageBannerContainer,
.pageBannerContainerMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.pageBannerContainer {
  gap: 10px;
}

.pageBannerContainerMobile {
  flex-direction: column;
  gap: 4px;
}

.bannerAction {
  display: inline;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
}

.bannerAction:hover {
  cursor: pointer;
}

.bannerCloseButton {
  position: absolute;
  top: 0;
  right: 0;
}
