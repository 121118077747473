.root {
  position: relative;
  display: inline-block;
}

.label {
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: var(--borderRadiusHalf);
}

.label:focus {
  outline: none;
  background-color: var(--matterColorLight);
  border-color: transparent;
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--boxShadowFilterButton);
}

.labelSelected {
  font-family: var(--buttonFontFamily), var(--fontFamily), 'sofiapro', sans-serif !important;
  font-weight: var(--fontWeightSemiBold);
  font-size: var(--buttonFontSize);
  line-height: var(--buttonLineHeight);
  letter-spacing: var(--buttonLetterSpacing);

  /* Dimensions */
  display: block;
  margin: 0;
  width: auto;
  height: auto;
  min-height: 0;
  padding: 9px 16px 10px 16px;

  /* Borders */
  border-radius: var(--borderRadiusHalf);
  border: 1px solid var(--marketplaceColor);

  /* Colors */
  background-color: var(--buttonColor);
  color: var(--buttonTextColor);

  /* Text */
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: var(--buttonTextTransform);

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .labelSelected {
    min-height: 65px;

    /* We dont want hover to occur on mobile  */
  }

  .labelSelected:hover,
  .labelSelected:focus {
    outline: none;
    background-color: var(--buttonColorHover);
    color: var(--buttonTextColor);
    -webkit-text-decoration: none;
    text-decoration: none;
  }
}

/* Make sure disabled selector is AFTER the hover/focus selector so that hover/focus does not apply to the disalbed selector */

.labelSelected:hover,
.labelSelected:focus {
  border: 1px solid var(--marketplaceColorDark);
}

.labelSelected:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  cursor: not-allowed;
  box-shadow: none;
  border: none;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 20px;
  padding-bottom: 30px;
  border: none;
}

.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;
}

@media only screen and (min-width: 768px) {
  .item {
    padding: 4px 0;
  }
}
