.root {
  outline: none;
}

.contentWrapper {
  margin-bottom: 24px;
}

.buttonsWrapper {
  display: flex;
  padding: 0 30px 16px 30px;
}

.clearButton {
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;
}

.clearButton:focus,
.clearButton:hover {
  color: var(--matterColor);
  transition: width var(--transitionStyleButton);
}

.cancelButton {
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;
}

.cancelButton:focus,
.cancelButton:hover {
  color: var(--matterColor);
  transition: width var(--transitionStyleButton);
}

.submitButton {
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;
}

.submitButton:focus,
.submitButton:hover {
  color: var(--marketplaceColorDark);
  transition: width var(--transitionStyleButton);
}
