.product {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--matterColor);
  width: 100%;
  position: relative;
  cursor: pointer;

  /* padding needed so that border is floating when item is selected */
  padding: var(--EditListingPage_productPadding);
}

.productImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.selectedProductImage {
  border: 2px solid var(--gray100);
}

.productTitle {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--matterColor);
  padding: var(--EditListingPage_productPadding);
  height: 100%;
  width: 100%;
}

.threeToTwoWrapper {
  /* Layout */
  border-radius: var(--borderRadius);
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  /* Just in case some photos are longer, for example on poplinen's store */
  overflow: hidden;
}

.threeToTwoWrapper:hover {
  transform: scale(1.02);
}

.selectedCheckMark {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: white;
  border-radius: 50%;
}

.searchCategory {
  height: 100%;
}

/* Hide label visually but not on screen readers */

.searchCategory label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.searchWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.showMoreButton {
  width: unset;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
