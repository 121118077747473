:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --Avatar_size: 40px;
  --Avatar_sizeMedium: 60px;
  --Avatar_sizeLarge: 96px;
}

/* Small Avatar */

.root {
  /* Font is specific to this component, but defining it in marketplace.css makes it easier
   * to change font if brand look-and-feel needs such changes.
   */
  font-family: 'sofiapro', Helvetica, Arial, sans-serif;
  border-radius: 50%;

  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background-image: linear-gradient(-180deg, var(--successColor) 0%, var(--successColor) 100%);
  color: var(--matterColorLight);
}

.root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.initials {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
}

.avatarImage {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

/* Small Avatar */
.smallAvatar {
  /* Fixed dimensions */
  width: var(--Avatar_size);
  height: var(--Avatar_size);
}

.smallAvatar .initials {
  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
}

/* Medium Avatar */

.mediumAvatar {
  /* Fixed dimensions */
  width: var(--Avatar_sizeMedium);
  height: var(--Avatar_sizeMedium);
}

.mediumAvatar .initials {
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
}

/* Large Avatar */

.largeAvatar {
  /* Fixed dimensions */
  width: var(--Avatar_sizeLarge);
  height: var(--Avatar_sizeLarge);
}

.largeAvatar .initials {
  font-size: 30px;
  font-weight: var(--fontWeightSemiBold);
  padding-bottom: 6px;
}

.bannedUserIcon {
  width: 100%;
  height: 100%;
}
