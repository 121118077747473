:root {
  /* Brand Direct icon tooltip */
  --ListingCard_brandDirectTooltipOffset: 16px;
  --ListingCard_brandDirectTooltipMinSize:
    calc(
      var(--marketplacebrandDirectIconMinSize) - var(--ListingCard_brandDirectTooltipOffset)
    );
  --ListingCard_brandDirectTooltipMaxSize:
    calc(
      var(--marketplacebrandDirectIconMaxSize) - var(--ListingCard_brandDirectTooltipOffset)
    );
  --ListingCard_brandDirectTooltipSize:
    calc(
      var(--marketplacebrandDirectIconSize) - var(--ListingCard_brandDirectTooltipOffset)
    );

  /* Keep in sync with ListAnItemCard.module.css */
  --ListingCard_textRowMinHeight: 22px;
}

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
}

/* Remove link's hover effect */

.root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .root {
    margin: 0;
  }
}

.threeToTwoWrapper {
  /* Layout */
  border-radius: var(--borderRadius);
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  /* FOr new stores change this  */
}

.threeToTwoWrapper:hover {
  transform: scale(1.02);
  box-shadow: var(--boxShadowListingCard);
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  /* Not sure how this calcuation is done.. but take 100% - shorter edge / longer edge */

  /* FOR NEW STORES CHANGE THIS  */
  padding-bottom: calc(266 / 302 * 100%); /* 13:6 Aspect Ratio */ /* 1:1 Aspect Ratio */

  /* background: var(--matterColorNegative); Loading BG color */
  border-radius: var(--borderRadius);
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);

  /* This makes sure if the image isn't the containers aspect ratio, it will center it in the middle */
  height: 100%;
  object-fit: contain;
  vertical-align: middle;
}

.hidden {
  opacity: 0;
}

.brandDirectIconWrapper {
  position: absolute;
  min-width: var(--ListingCard_brandDirectTooltipMinSize);
  min-height: var(--ListingCard_brandDirectTooltipMinSize);
  width: var(--ListingCard_brandDirectTooltipSize);
  height: var(--ListingCard_brandDirectTooltipSize);
  max-width: var(--ListingCard_brandDirectTooltipMaxSize);
  max-height: var(--ListingCard_brandDirectTooltipMaxSize);
}

.favoriteButton {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  padding: 0;
  padding-left: 4px;
}

.favoriteButtonIcon {
  height: var(--ListingCard_textRowMinHeight);
  width: var(--ListingCard_textRowMinHeight);
}

.favoriteButtonOnImage {
  padding: var(--listingIconPadding);
  position: absolute;
  right: 0;
}

.favoriteButtonIconOnImage {
  height: 28px;
  width: 28px;
}

.textRow {
  display: flex;
  align-items: center;
  min-height: var(--ListingCard_textRowMinHeight);
}

.price {
  justify-content: flex-start;
  flex-shrink: 0;
}

.priceValue {
  color: var(--priceColor);

  /* Remove default margins from font */
  margin: 0 8px 0 0;
}

.originalPriceValue {
  color: var(--matterColorAnti);
  font-weight: var(--fontWeightRegular);
  margin: 0;
}

.size,
.color {
  font-weight: var(--fontWeightRegular);
  margin: 0;
}

.perUnit {
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .perUnit {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title {
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  width: 100%;
}

.authorInfo {
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .authorInfo {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;

  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
  font-family: inherit;
}
