.root {
  width: 100%;
  background-color: var(--gray10);
  overflow-x: auto;
}

.logo {
  margin-left: 4px;
  max-width: 120px;
}
