.root {
  /* Need to explicitly override here because on mobile the disabled state causes it to appear a lighter shade */
  color: var(--matterColor);
}

.input {
  border-bottom-color: var(--gray80);
}

.inputSuccess {
  border-bottom-color: var(--gray80);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.optional {
  color: var(--matterColorAnti);
}
