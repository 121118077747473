.root {
  height: 16px;
  width: auto;
  fill: var(--socialIconsColor);
  transition: var(--transitionStyleButton);
}

.root:hover {
  fill: var(--socialIconsColorHover);
}
