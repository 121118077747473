.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;
}

@media only screen and (min-width: 768px) {
  .item {
    padding: 4px 0;
  }
}
