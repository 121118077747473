.brandDirectIcon {
  min-width: var(--marketplacebrandDirectIconMinSize);
  min-height: var(--marketplacebrandDirectIconMinSize);
  padding: var(--listingIconPadding);
}

.customBrandDirectIcon {
  min-width: var(--marketplacebrandDirectIconMinSize);
  min-height: var(--marketplacebrandDirectIconMinSize);
  padding: var(--listingIconPadding);
  display: flex;
  justify-content: center;
  align-items: center;
}
