.root {
  position: relative;
}

.checked {
  display: none;
  stroke: var(--gray80);
  fill: var(--gray80);
}

.notChecked {
  stroke: var(--matterColorAnti);
}

.required {
  stroke: var(--gray80);
}

.text {
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.radioButtonWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -2px;
  margin-right: 12px;
  align-self: baseline;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */

  /* Highlight the text on checked, hover and focus */

  /* Disable option */
}

/* Display the "check" when checked */

.input:checked + label .checked {
  display: inline;
}

.input:hover + label .notChecked,
.input:hover + label .required,
.input:focus + label .notChecked,
.input:focus + label .required,
.input:checked + label .notChecked,
.input:checked + label .required {
  stroke: var(--matterColorDark);
}

.input:focus + label .text,
.input:hover + label .text,
.input:checked + label .text {
  color: var(--matterColorDark);
}

.input:disabled + label .notChecked,
.input:disabled + label .required,
.input:disabled + label .radioButtonWrapper {
  stroke: var(--matterColorAnti);
  cursor: default;
}

.label {
  display: flex;
  align-items: center;
  padding-top: 6px;
}
