.dialogHeader {
  padding: 0 !important; /* Override default MuiDialogTitle styles */
}

.formRoot {
  margin: 24px;
}

@media only screen and (min-width: 768px) {
  .formRoot {
    margin: 36px;
  }
}
