.buttonOnImage {
  max-width: 280px;
  margin: 0 auto;
  width: 100%;
}

.mobileButtonCondensed {
  width: unset;
}

.comingSoonImg {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

@media only screen and (min-width: 550px) {
  .comingSoonImg {
    width: 100%;
    border-top-left-radius: inherit;
  }
}

@media only screen and (min-width: 768px) {
  .comingSoonImg {
    width: auto;
    border-top-left-radius: unset;
  }
}

.dialogHeader {
  height: 0; /* Unset the dialog header's height so we can control the header */
  padding: 0 !important; /* Override default MuiDialogTitle styles */
}

.dialogCloseButton {
  position: fixed !important; /* Override default MuiButtonBase styles */
  top: unset;
  right: unset;
  margin: 4px 0 !important; /* Override default MuiButtonBase styles */
  z-index: var(--zIndexModal);
}

.divider {
  background-color: var(--gray20);
}
