.icon {
  position: relative;
  bottom: 1px;
  margin-right: 5px;
}

@media only screen and (min-width: 768px) {
  .icon {
    bottom: 2px;
  }
}

.menuLabel {
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: var(--borderRadiusHalf);
  white-space: nowrap;
}

.menuLabel:focus {
  outline: none;
  background-color: var(--matterColorLight);
  border-color: transparent;
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--boxShadowFilterButton);
}

.menuContent {
  margin-top: 7px;
  padding-top: 0;
  padding-bottom: 24px;
  min-width: 300px;
  border-radius: var(--borderRadiusHalf);
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuHeading {
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
  margin-top: 24px;
  margin-left: 30px;
  margin-right: 30px;
}

.menuItem {
  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;
}

.menuItem:focus,
.menuItem:hover {
  color: var(--matterColorDark);
}

.menuItem:hover .menuItemBorder {
  width: 6px;
}

.menuItem:disabled {
  color: var(--matterColorAnti);
  cursor: default;
}

.menuItem:disabled:hover .menuItemBorder {
  width: 0;
}

.clearMenuItem {
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;
  transition: width var(--transitionStyleButton);
}

.clearMenuItem:focus,
.clearMenuItem:hover {
  color: var(--matterColor);
  transition: width var(--transitionStyleButton);
}
