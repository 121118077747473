:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarMobileMenu_topMargin: 60px;
}

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 180px;

  /* Needed so that we can reach content at the bottom when we scroll the menu */
  padding-bottom: 100px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  /* Logout font is smaller and gray since the action is not recommended. */
  color: var(--matterColorAnti);
  display: inline;
  margin: 4px 0 6px 0;
  padding: 0;
  border: none;
  -webkit-text-decoration: none;
  text-decoration: none;

  /* Position component */
  width: initial;
}

.inbox {
  color: var(--marketplaceColor);
  position: relative;
  margin-top: auto;
  margin-bottom: 13px;
}

.navigationLink {
  color: var(--marketplaceColor);

  /* Layout */
  margin: 8px 0;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}
