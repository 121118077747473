.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .root {
    justify-content: space-between;
  }
}

.bottomWrapper {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media only screen and (min-width: 768px) {
  .bottomWrapper {
    margin-top: 72px;
  }
}
