.root {
  display: inline-block;
}

.root:last-of-type {
  padding-right: 0;
}

.menuLabel {
  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: var(--borderRadiusHalf);
}

.menuLabel:focus {
  outline: none;
  background-color: var(--matterColorLight);
  border-color: transparent;
  -webkit-text-decoration: none;
  text-decoration: none;
  box-shadow: var(--boxShadowFilterButton);
}

.menuLabelSelected {
  font-weight: var(--fontWeightSemiBold);
  width: auto;
  height: auto;
  padding: 9px 16px 10px 16px;
  border-radius: var(--borderRadiusHalf);
  border: 1px solid var(--marketplaceColor);
}

.menuLabelSelected:hover,
.menuLabelSelected:focus {
  border: 1px solid var(--marketplaceColorDark);
}

.menuContent {
  margin-top: 7px;
  padding-top: 13px;
  min-width: 300px;
  border-radius: var(--borderRadiusHalf);
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuItem {
  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;
}

.menuItem:focus,
.menuItem:hover {
  color: var(--matterColorDark);
}

.menuItem:hover .menuItemBorder {
  width: 6px;
}

.clearMenuItem {
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;
  transition: width var(--transitionStyleButton);
}

.clearMenuItem:focus,
.clearMenuItem:hover {
  color: var(--matterColor);
  transition: width var(--transitionStyleButton);
}
