.actionButton {
  background-color: var(--successColor);
  color: white;
  width: 100%;
}

@media only screen and (min-width: 550px) {
  .actionButton {
    width: auto;
  }
}
