.countryWarningModalDialogHeader {
  padding: 0 !important;
}

.countryWarningContentBackground {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.customButtonLink {
  width: 100%;
}

.customButtonLink:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}
