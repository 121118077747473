.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .root {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.firstNameRoot {
  width: calc(34% - 9px);
  white-space: nowrap;
}

.lastNameRoot {
  width: calc(66% - 9px);
  white-space: nowrap;
}

.password {
  /* Leave space between the input and the button below when the
    viewport height is small */
  margin-top: 24px;
}

@media only screen and (min-width: 768px) {
  .password {
    margin-top: 32px;
  }
}

.bottomWrapper {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media only screen and (min-width: 768px) {
  .bottomWrapper {
    margin-top: 72px;
  }
}

.bottomWrapperText {
  padding-bottom: 8px;
  margin: 0 0 24px 0;
}

@media only screen and (min-width: 768px) {
  .bottomWrapperText {
    padding-bottom: 0;
  }
}

.termsCheckboxWrapper {
  margin-top: 4px; /* Manually inspected */
  margin-right: 12px;
  display: inline-flex;
  cursor: pointer;
}

.termsText {
  margin: 0;
  text-align: start;
  color: var(--matterColor);
}

@media only screen and (min-width: 768px) {
  .termsText {
    margin: 0;
  }
}

.usOnly {
  color: var(--matterColor);
  margin: 0;
  text-align: start;
  margin-bottom: 8px;
}

@media only screen and (min-width: 768px) {
  .usOnly {
    margin: 0 0 8px 0;
  }
}

.readOnly {
  /* If its read only, grey out the text and don't change the border color */
  border-color: var(--matterColor);
  color: var(--matterColorAnti);
}
