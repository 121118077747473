.root {
  display: flex;
  flex-direction: column;
}

.loading {
  padding: 32px;
  width: 100%;
}

.card {
  /* Dimensions */
  display: block;
  margin: 0;
  padding: 8px 0 6px 0;
  height: 36px;

  /* Borders */
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--gray80);
  border-radius: 0;
  transition: border-bottom-color var(--transitionStyle);

  /* Effects */
}

.card::placeholder {
  color: var(--matterColorAnti);
}

.card:hover,
.card:focus {
  border-bottom-color: var(--matterColor);
  outline: none;
}

@media only screen and (min-width: 768px) {
  .card {
    padding: 4px 0;
    height: 38px;
  }
}

@media only screen and (min-width: 1024px) {
  .card {
    height: 38px;
    padding: 6px 0 14px 0;
  }
}

.cardSuccess {
  border-bottom-color: var(--gray80);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 8px 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 2px;
}

@media only screen and (min-width: 768px) {
  .paymentHeading {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  .paymentMethodSelector {
    margin-bottom: 15px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
}

@media only screen and (min-width: 768px) {
  .saveForLaterUse {
    padding-top: 8px;
    margin-top: 0;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;
}

@media only screen and (min-width: 768px) {
  .saveForLaterUseCheckbox {
    padding: 1px 0 0 0;
  }
}

.saveForLaterUseLabel {
  color: var(--matterColor);
  margin-top: 1px;
  margin-bottom: 0;
  cursor: pointer;
}

.clearAddressLabel {
  color: var(--matterColor);
  margin-top: 1px;
  margin-bottom: 0;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0 0 0 26px;
  margin-top: -2px;
  margin-bottom: 2px;
}

@media only screen and (min-width: 768px) {
  .saveForLaterUseLegalInfo {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 2px;
}

@media only screen and (min-width: 768px) {
  .billingHeading {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.shippingHeading {
  margin: 24px 0 0 0;
  color: var(--matterColorAnti);
  padding-bottom: 4px;
  padding-bottom: 2px;
}

@media only screen and (min-width: 768px) {
  .shippingHeading {
    /* margin: 0px 40px 0px 0; */
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentLabel {
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .paymentLabel {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 36px 0 12px 0;
  padding-top: 4px;
  padding-bottom: 2px;
}

@media only screen and (min-width: 768px) {
  .messageHeading {
    margin: 24px 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;
}

@media only screen and (min-width: 768px) {
  .messageLabel {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;
  padding: 0 16px 16px;
}

@media only screen and (min-width: 768px) {
  .submitContainer {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .submitContainer {
    margin-top: 10px;
  }
}

.paymentInfo {
  color: var(--matterColorAnti);
  margin-top: 14px;
  margin-bottom: 60px; /* Make sure button isn't blocked by Safari mobile browser navigation links */
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .paymentInfo {
    padding: 0 42px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .paymentInfo {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
}

.submitButton {
  margin-top: 16px;
}

.submittingWarning {
  text-align: center;
}

.missingStripeKey {
  color: var(--failColor);
}
