.input {
  border-bottom-color: var(--gray80);
}

.inputSuccess {
  border-bottom-color: var(--gray80);
}

.inputError {
  border-bottom-color: var(--failColor);
}
