.dialogHeader {
  height: 0; /* Unset the dialog header's height so we can control the header */
  padding: 0 !important; /* Override default MuiDialogTitle styles */
}

.dialogCloseButton {
  position: fixed !important; /* Override default MuiButtonBase styles */
  top: unset;
  right: unset;
  margin: 4px 0 !important; /* Override default MuiButtonBase styles */
  z-index: var(--zIndexModal);
}

.content {
  max-height: 70vh;
}

.footer {
  position: sticky;
  bottom: 0;
}

.divider {
  background-color: var(--gray20);
}

.footerButton {
  width: unset;
}
