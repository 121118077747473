.newPasswordSection {
  margin-top: 16px;
  margin-bottom: 46px;
  padding-top: 6px;
}

@media only screen and (min-width: 768px) {
  .newPasswordSection {
    margin-bottom: 56px;
    padding-top: 0;
  }
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  padding: 0;
  opacity: 0;
}

.confirmChangesSectionVisible {
  opacity: 1;
}

.confirmChangesTitle {
  /* Font */
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 13px;
}

@media only screen and (min-width: 768px) {
  .confirmChangesTitle {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: 37px;
}

@media only screen and (min-width: 768px) {
  .confirmChangesInfo {
    margin-bottom: 48px;
  }
}

.bottomWrapper {
  margin-top: 46px;
}

@media only screen and (min-width: 768px) {
  .bottomWrapper {
    margin-top: 96px;
  }
}

.error {
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;
}

@media only screen and (min-width: 768px) {
  .error {
    margin: 24px 0;
  }
}
