/* stylelint-disable function-linear-gradient-no-nonstandard-direction */
:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --PropertyGroup_lineHeight: 24px;
  --PropertyGroup_lineThroughTop: calc(var(--PropertyGroup_lineHeight) - 7px);
  --PropertyGroup_lineThroughBottom: calc(var(--PropertyGroup_lineHeight) - 6px);
  --PropertyGroup_lineThroughTopMobile: calc(var(--PropertyGroup_lineHeight) - 9px);
  --PropertyGroup_lineThroughBottomMobile: calc(var(--PropertyGroup_lineHeight) - 8px);
}

.root {
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .twoColumns {
    column-count: 2;
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 3px 0;
}

@media only screen and (min-width: 768px) {
  .item {
    padding: 4px 0;
  }
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: var(--PropertyGroup_lineHeight);
}

.labelWrapper {
  display: inline-block;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
}

.notSelectedLabel {
  color: var(--matterColorNegative);
  position: relative;

  /* line-through */
  background-image:
    linear-gradient(
      transparent var(--PropertyGroup_lineThroughTopMobile),
      var(--matterColorNegative) var(--PropertyGroup_lineThroughTopMobile),
      var(--matterColorNegative) var(--PropertyGroup_lineThroughBottomMobile),
      transparent var(--PropertyGroup_lineThroughBottomMobile)
    );
}

@media only screen and (min-width: 768px) {
  .notSelectedLabel {
    background-image:
      linear-gradient(
        transparent var(--PropertyGroup_lineThroughTop),
        var(--matterColorNegative) var(--PropertyGroup_lineThroughTop),
        var(--matterColorNegative) var(--PropertyGroup_lineThroughBottom),
        transparent var(--PropertyGroup_lineThroughBottom)
      );
  }
}
