.addLink {
  padding: 16px;
}

.discountCodeWrapper {
  padding: 16px;
}

.applyButtonWrapper {
  display: flex;
  align-self: flex-end;
  padding-left: 0 !important; /* Override grid padding */
}

.applyButton {
  padding-left: 0;
  padding-right: 0;
}
