/* Content is visible as modal layer */
.isOpen {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available; /* For Safari */
  width: 100%;
  z-index: 100;
}

/* scrollLayer is the .root for the Modal */
.isOpen .scrollLayer {
  display: flex;
  flex-grow: 1;

  /* Additional styles for the modal window, dimming the background and positioning the modal */
  min-height: 100vh;
  min-height: -webkit-fill-available; /* For Safari */
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
}

@media only screen and (min-width: 768px) {
  .isOpen .scrollLayer {
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    background-image: none;
  }
}

.isOpen .container {
  /* Create context so the close button can be positioned within it */
  position: relative;

  /* Display and dimensions */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--modalPadding);
  border-radius: var(--borderRadius);
  border-bottom: none;
  min-height: 100vh;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .isOpen .container {
    flex-grow: 0;
    flex-basis: 576px;
    min-height: auto;
    height: auto;
    padding: var(--modalPaddingMedium);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */

/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */

/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  /* Position inside modal */
  position: fixed;
  top: 0;
  left: 0;

  /* Some content (like map) is positioning itself on top of close button without z-index handling */
  z-index: calc(var(--zIndexModal) + 1);

  /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: center;
  width: auto;

  /* Sizing (touch area) */
  padding: 24px;
  margin: 0;
  border: 0;

  /* Colors */
  background-color: transparent;
  color: var(--matterColor);
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .close {
    padding: 27px 30px;
    position: absolute;
  }
}

.close:disabled {
  background-color: transparent;
}

.close:enabled:hover {
  background-color: transparent;
  color: var(--matterColorDark);
  box-shadow: none;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.close:enabled:active {
  background-color: transparent;
  color: var(--matterColorDark);
}

.closeText {
  margin-left: 8px;
}

.closeLight {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);
}

.closeLight:enabled:hover,
.closeLight:enabled:active {
  color: var(--matterColorLight);
}

.focusedDiv:focus {
  outline: none;
}
