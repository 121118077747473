.progressBar {
  width: 100%;
  position: sticky;
  top: 58px;
  z-index: calc(var(--zIndexTopbar) - 1);
  background-color: var(--matterColorBright);
  padding: 16px 0;
  line-height: 1;
}

@media only screen and (min-width: 768px) {
  .progressBar {
    top: 82px;
  }
}

.sizeIconForMobile {
  height: 12px;
  width: 16px;
}

.sizeIconForDesktop {
  height: 14px;
  width: 28px;
}
