.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .root {
    margin: 24px;
    height: auto;
    width: 83%;
    position: relative;
  }
}

.text {
  flex: 1;
  text-align: center;
}

@media (min-width: 1000px) {
  .text {
    margin: 0;
    position: absolute;
    height: auto;
    width: 36%;
    min-width: 630px;
    right: 0;
    transform: translate(0%, -50%);
    top: 50%;
    overflow: auto;
  }
}

.title {
  line-height: 40px;
  margin: 0;
}

.description {
  margin: 0;
  line-height: 30px;
}

.description p {
  margin: 10px 0;
}

.condensedDescription p {
  margin: 4px 0;
}

/* A wrapper for a responsive image that holds its aspect ratio */
.imageWrapper {
  width: 100%;
  flex: 1;
  border-radius: var(--borderRadiusHalf);
  transition: var(--transitionStyleButton);
}

.aboutImage {
  width: 100%;
  border-radius: var(--borderRadiusHalf);
  object-fit: cover;
}

@media (min-width: 1000px) {
  .aboutImage {
    width: 62vw;
    height: 43vw;
  }
}

.button {
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  justify-self: flex-end;
  margin-top: 10px;
  text-align: center;
  vertical-align: middle;
  font-weight: var(--buttonFontWeight);
}

@media only screen and (min-width: 768px) {
  .button {
    margin-bottom: 36px;
    padding-bottom: 12px;
    display: block;
    width: 240px;
    margin-right: auto;
  }
}
