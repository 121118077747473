/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.subscribeButton {
  height: 48px;
  margin: 0;
  max-width: unset;
  width: 100%;

  /* Setting padding as 0 so it can assume whatever the height is of the inputs */
  padding: 0;
}

.subscribeSpinner {
  stroke: var(--matterColor);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}
