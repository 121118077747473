.root p {
  margin-bottom: 12px;
  margin-top: 12px;
}

.root h2 {
  margin: 24px 0;
}

.root li {
  margin-bottom: 12px;
  margin-top: 12px;
}

.root .list {
  list-style: lower-roman;
  padding-left: 32px;
}
