.menuSection {
  justify-content: flex-start;
  flex-grow: 1;
  flex-basis: 0;
}

.linkSection {
  justify-content: flex-end;
  flex-grow: 1;
  flex-basis: 0;
}

/* Child component's size (and positioning) should be given here */
.menu,
.shoppingBag {
  color: var(--matterColor);

  /* Layout */
  border-width: 0;
  background-color: transparent;
  display: flex;
}

/* Hovers */
.menu:enabled,
.shoppingBag:enabled {
  cursor: pointer;
}

.menu:disabled,
.shoppingBag:disabled {
  background-color: transparent;
  cursor: auto;
}

.menu:enabled:hover,
.menu:enabled:active,
.shoppingBag:enabled:hover,
.shoppingBag:enabled:active {
  background-color: transparent;
  box-shadow: none;
}

.shoppingBagHidden {
  visibility: hidden;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* padding: calc((var(--topbarHeight) - var(--Topbar_logoHeight)) / 2) 24px; */
  padding: 8px 24px;
  height: 100%;
}

.rootMenuIcon {
  fill: var(--matterColor);
}
