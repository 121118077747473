.root {
  position: relative;
}

.boxSuccess,
.box {
  stroke: var(--gray80);
  fill: none;
  stroke-width: 2px;
}

.checkboxWrapper {
  margin-right: 12px;
  display: inline-flex;
  cursor: pointer;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Highlight the borders if the checkbox is hovered, focused or checked */

.input:hover + label .box,
.input:focus + label .box,
.input:checked + label .box {
  stroke: var(--gray80);
}

/* successColor version */

.input:hover + label .boxSuccess,
.input:focus + label .boxSuccess,
.input:checked + label .boxSuccess {
  stroke: var(--gray80);
}

/* Display the "check" when checked */

.input:checked + label .box {
  display: inline;
  stroke: var(--gray80);
  stroke-width: 1px;
  fill: var(--gray80);
}

/* Display the "check" when checked */

.input:checked + label .boxSuccess {
  display: inline;
  stroke: var(--gray80);
  stroke-width: 1px;
  fill: var(--gray80);
}

/* Highlight the text on checked, hover and focus */

.input:focus + label .text,
.input:hover + label .text,
.input:checked + label .text {
  color: var(--matterColorDark);
}

/* Disable option */

.input:disabled + label .box {
  stroke: var(--matterColorAnti);
  cursor: default;
}

.input:disabled + label .checkboxWrapper {
  cursor: default;
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
}

.textRoot {
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
