.listingImage {
  border: 2px solid var(--gray10);
  margin-bottom: var(--Bundle_listingImageMarginBottom);
  margin-right: 12px;
  height: var(--Bundle_listingImageHeight);
  width: auto;
}

.listing {
  position: relative;
}

.removeListingButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 12px;
  position: relative;
  margin-bottom: -48px;
  margin-left: 100%;
  bottom: calc(32px + var(--Bundle_listingImageHeight));
  right: 40px;
}

.removeIcon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  color: var(--red80);
  border-radius: 12px;
}

@media only screen and (min-width: 768px) {
  .removeIcon:not(:hover) {
    color: var(--gray80);
    background-color: var(--gray10);
    border: 1px solid var(--gray10);
  }
}
