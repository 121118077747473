.previousButton {
  padding: 8px;
}

.hidden {
  display: none;
}

.dialogHeader {
  padding: 24px !important; /* Override default MuiDialogTitle styles */
}
