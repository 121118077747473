.empty {
  align-self: center;
  height: 80%;
}

.emptyText {
  padding-top: 20px;
}

.emptyButton {
  padding-top: 20px;
  width: 100%;
}
