.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.actionIcon {
  margin-right: 6px;
  height: 22px;
  width: 22px;
}
