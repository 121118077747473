.root h4 {
  margin: 0;
}

.divider {
  background-color: var(--gray20);
}

.externalLink:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
}
