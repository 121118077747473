@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.root {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.imageOverlay {
  background: linear-gradient(-46deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.3));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.image {
  min-height: 400px;
  max-height: 680px;
  height: 100%;
  width: 100%;
  text-align: center;
  object-fit: cover;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.titleAnimation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  visibility: hidden;
  opacity: 1;
  position: relative;
  animation-delay: 0.5s;
}

.subtitleAnimation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  visibility: hidden;
  opacity: 1;
  position: relative;
  animation-delay: 0.65s;
}

.actionElAnimation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  visibility: hidden;
  opacity: 1;
  position: relative;
  animation-delay: 0.85s;
}

.bottomActionElWrapper {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
  position: absolute;
  bottom: 0;
  padding: 8px 0;
  width: 100%;
}

.bottomActionElAnimation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  visibility: hidden;
  opacity: 1;
  position: relative;
  animation-delay: 1.15s;
}
