.root p {
  margin-bottom: 12px;
  margin-top: 12px;
}

.root h2 {
  /* Adjust heading margins to work with the reduced body font size */
  margin: 23px 0 19px 0;
}

@media only screen and (min-width: 768px) {
  .root h2 {
    margin: 24px 0 24px 0;
  }
}
