.root {
  display: flex;
  flex-direction: column;
}

.paymentAddressField {
  padding-top: 38px;
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
}

@media only screen and (min-width: 550px) {
  .formRow {
    flex-wrap: no-wrap;
  }
}

.field {
  width: 100%;
  margin-top: 24px;
}

@media only screen and (min-width: 550px) {
  .field {
    width: calc(50% - 12px);
  }
}

.narrowField {
  width: 100%;
  margin-top: 24px;
}

@media only screen and (min-width: 550px) {
  .narrowField {
    width: calc(25% - 12px);
  }
}
