:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TabNav_linkWidth: 240px;
  --TabNav_linkBorderWidth: 4px;
}

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;
}

.tab:first-child {
  margin-left: 0;
}

@media only screen and (min-width: 768px) {
  .tab {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--matterColor);
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  transition: var(--transitionStyleButton);
  background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + var(--TabNav_linkBorderWidth)) center; /* SelectedLink's width (200px) + border thickness (4px) = 204px */
}

.link:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  color: var(--matterColorDark);
}

@media only screen and (min-width: 768px) {
  .link {
    font-weight: var(--fontWeightSemiBold);
    font-size: 24px;
    line-height: 32px;
    min-width: var(--TabNav_linkWidth);
    border-bottom-width: 0;
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 6px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 2px;
    padding-right: 24px;
    background-image: none;
  }

  .link:hover {
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
    background-position: right center;
  }
}

.selectedLink {
  border-bottom-color: var(--matterColorDark);
  color: var(--matterColorDark);
}

@media only screen and (min-width: 768px) {
  .selectedLink {
    min-width: var(--TabNav_linkWidth);
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
    background-position: right center;
  }

  .selectedLink:hover {
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
    background-position: right center;
  }
}

.disabled {
  pointer-events: none;
  -webkit-text-decoration: none;
  text-decoration: none;
}
