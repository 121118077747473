/* react-aria-components Date Picker classes */

.react-aria-DateRangePicker .react-aria-Group {
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: 220px;
  max-width: 100%;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  padding: 4px 4px 4px 8px;
  border: 1px solid var(--gray20);
  border-radius: 6px;
  white-space: nowrap;
}

.react-aria-DateRangePicker .react-aria-Group[data-pressed] {
  box-shadow: none;
  background: var(--gray10);
}

.react-aria-DateRangePicker .react-aria-Group[data-focus-within] {
  outline: 1px solid var(--gray40);
  outline-offset: -1px;
}

.react-aria-DateRangePicker [slot='end'] {
  margin-right: 1rem;
  flex: 1;
}

.react-aria-DateRangePicker .react-aria-DateInput {
  display: flex;
  width: unset;
  min-width: unset;
  padding: unset;
  border: unset;
  outline: unset;
}

.react-aria-DateRangePicker .react-aria-DateSegment {
  font-size: 16px;
  text-transform: uppercase;
  outline: none;
}

.react-aria-Popover[data-trigger='DateRangePicker'] {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray00);
  border: 1px solid var(--gray40);
  border-radius: 6px;
  padding: 8px 16px;
  min-width: 300px;
  max-width: unset;
}

.react-aria-Dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.react-aria-RangeCalendar {
  width: fit-content;
  max-width: 100%;
}

.react-aria-RangeCalendar table {
  border-collapse: collapse;
}

.react-aria-RangeCalendar table td {
  padding: 2px 0;
}

.react-aria-CalendarGrid {
  width: 100%;
}

.react-aria-CalendarHeaderCell {
  font-size: 14px;
}

.react-aria-CalendarCell {
  font-size: 14px;
  width: 2.286rem;
  line-height: 2.286rem;
  text-align: center;
  border-radius: 6px;
  outline: none;
  forced-color-adjust: none;
  cursor: pointer;
}

.react-aria-CalendarCell[data-outside-month] {
  display: none;
}

.react-aria-CalendarCell[data-focus-visible] {
  outline: 2px solid var(--gray80);
  outline-offset: -2px;
}

.react-aria-CalendarCell[data-selected] {
  background: var(--gray80);
  color: var(--gray00);
  border-radius: 0;
}

.react-aria-CalendarCell[data-selected][data-focus-visible] {
  outline-color: var(--gray00);
  outline-offset: -3px;
}

.react-aria-CalendarCell[data-selection-start] {
  border-start-start-radius: 6px;
  border-end-start-radius: 6px;
}

.react-aria-CalendarCell[data-selection-end] {
  border-start-end-radius: 6px;
  border-end-end-radius: 6px;
}
