.root {
  width: 100%;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--matterColorNegative);
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  min-height: 80px;
  padding: 8px;
}

.noImageIcon {
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.noImageText {
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 8px;
  color: var(--matterColor);
}

@media only screen and (min-width: 768px) {
  .noImageText {
    margin-bottom: 0;
  }
}
