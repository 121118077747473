.root {
  font-family: var(--buttonFontFamily), var(--fontFamily), 'sofiapro', sans-serif !important;
  font-weight: var(--buttonFontWeight);
  font-size: var(--buttonFontSize);
  line-height: var(--buttonLineHeight);
  letter-spacing: var(--buttonLetterSpacing);
  display: block;
  width: 100%;
  min-height: 59px;
  margin: 0;
  padding: var(--buttonPadding);
  border: var(--buttonBorder);
  border-radius: var(--borderRadius);
  background-color: var(--buttonColor);
  color: var(--buttonTextColor);
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: var(--buttonTextTransform);
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  /* Relative because we use absolutely-positioned banners on top of buttons like "Coming Soon" */
  position: relative;
}

@media only screen and (min-width: 768px) {
  .root {
    min-height: 36px;
  }
}

.primaryButtonRoot {
  composes: root;
}

.secondaryButtonRoot {
  composes: root;
  border-color: var(--matterColorNegative);
  border-radius: var(--borderRadius);
  background-color: var(--matterColorLight);
  color: var(--matterColor);
  text-transform: var(--buttonTextTransform);
  transition: all var(--transitionStyleButton);
}

.inlineTextButtonRoot {
  display: inline-flex;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.primaryButton {
  /* Class handle for primary button state styles */
}

.secondaryButton {
  /* Class handle for secondary button state styles */
}

.primaryButton.inProgress:disabled {
  background-color: var(--successColor);
}

.secondaryButton.inProgress:disabled {
  background-color: var(--matterColorLight);
}

.ready {
  /* padding: 0; */
}

.ready:disabled {
  background-color: var(--marketplaceColor);
}

.primaryButton.ready:disabled {
  background-color: var(--successColor);
}

.secondaryButton.ready:disabled {
  background-color: var(--matterColorLight);
}

.spinner {
  width: 28px;
  height: 28px;
  stroke-width: 3px;
}

.checkmark {
  width: 24px;
  height: 24px;

  /* Animation */
  animation-name: checkmarkAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
}

@keyframes checkmarkAnimation {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.secondaryButton .spinner {
  stroke: var(--matterColorAnti);
}

.secondaryButton .checkmark {
  stroke: var(--matterColorAnti);
}

/* Social logins && SSO buttons */

.socialButtonRoot {
  composes: root;
  background-color: transparent;
  color: var(--matterColorDark);
  font-weight: var(--fontWeightMedium);
  font-size: var(--fontSizeSmall);
  letter-spacing: 1px;
  border: 1px solid var(--matterColorNegative);
  border-radius: var(--borderRadius);
}

@media only screen and (min-width: 768px) {
  .socialButtonRoot {
    letter-spacing: 2px;
  }
}

.socialButtonRoot:hover,
.socialButtonRoot:focus {
  background-color: transparent;
  color: var(--matterColorDark);
}

.socialButtonRoot:disabled {
  background-color: var(--matterColorNegative);
}
