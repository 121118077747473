:root {
  /* Keep in sync with ListingCard.module.css */
  --ListAnItemCard_textRowMinHeight: 22px;
}

.aspectWrapper {
  position: relative;
  border-radius: var(--borderRadius);
  background-color: var(--gray10);
  border: 1px solid var(--gray20);
}

.content {
  width: 100%;
  max-width: 256px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button {
  min-height: auto;
  padding: var(--listingCardButtonPadding);
}

.textRow {
  display: flex;
  align-items: center;
  min-height: var(--ListingCard_textRowMinHeight);
}
